<template>
	<app-text v-if="MIX_getFromLocalStorage('currentSite')"
			  class="d-flex justify-center text-center mt-4"
			  size="xlarge">

		{{ title }}

	</app-text>
</template>

<script>
export default {

	name: "PageTitle",

	props: ['title'],

}
</script>

<style scoped>

</style>
