<template>
	<div class="white rounded-lg">

		<!--Toolbar-->
		<v-toolbar class="rounded-t-lg pl-2" color="primary">
			<app-text color="white" size="medium">Help</app-text>
			<v-spacer/>
			<close-icon @click.native="$emit('closeHelpDialog')"/>
		</v-toolbar>

		<div class="d-flex flex-column align-center pa-4">

			<app-text size="medium-bold">How to use this QR Code Scanner</app-text>

			<ul class="mt-4">
				<li><app-text>
					Hold your QR Code up to the camera so it can be seen on the screen
				</app-text></li>
				<li><app-text>
					Ensure it fits inside the screen and is not too close, or too far away
				</app-text></li>
				<li><app-text>
					Do not cover any part of the QR Code
				</app-text></li>
				<li><app-text>
					Ensure it's not too bright, or too dark
				</app-text></li>
			</ul>

			<app-text class="mt-4">
				For best results, hold the QR up close to the camera so it's in the middle of the screen, and slowly draw it away
			</app-text>

			<app-btn @click.native="$emit('closeHelpDialog')"
				:block="true"
				class="mt-4"
				label="Close"/>

		</div>

	</div>
</template>

<script>
export default {
	name: "ReverseHelpDialog"
}
</script>

<style scoped>

</style>
