<template>
	<div v-if="MIX_getFromLocalStorage('currentSite')" class="d-flex flex-column align-center">

		<page-title title="Are you a Regular or Temporary visitor?"/>

		<app-text class="d-flex justify-center mt-8 mx-4" size="normal">
			If you are a Regular visitor (weekly to monthly visits),
			please click the REGULAR button below and follow the prompts to either sign in,
			or register an account
			<br>
			<br>
			If you are a Temporary visitor (monthly to annual visits),
			your account will be delete after 48 hours,
			please click the TEMPORARY button below and follow the prompts to either sign in,
			or register an account
		</app-text>

		<!--Action Tiles-->
		<div class="d-flex justify-center mt-8 mx-4" style="width: calc(100% - 64px)">

			<!--Regular-->
			<app-btn @click.native="regularOrTemporary = 'Regular'"
					 class="mr-4 pa-8"
					 label="Regular"
					 style="width: 50%"/>

			<!--Temporary-->
			<app-btn @click.native="regularOrTemporary = 'Temporary'"
					 class="ml-4 pa-8"
					 label="Temporary"
					 style="width: 50%"/>

		</div>

		<!--Regular ------------------------------------------------------------------------------------------------ -->

		<div v-if="regularOrTemporary === 'Regular'" style="width: calc(100% - 32px)">

			<app-text class="d-flex justify-center mt-8" size="large">
				Have you created an account before?
			</app-text>

			<!--Action Tiles-->
			<div class="d-flex justify-center mt-8 mx-4">

				<!--Regular-->
				<app-btn @click.native="MIX_go('SwappMethod')"
						 class="mr-4 pa-8"
						 label="Yes"
						 style="width: 50%"/>

				<!--Temporary-->
				<app-btn @click.native="isRegistrationFormVisible = true"
						 class="ml-4 pa-8"
						 label="No"
						 style="width: 50%"/>

			</div>

		</div>

		<!--Temporary ---------------------------------------------------------------------------------------------- -->

		<div v-if="regularOrTemporary === 'Temporary'" style="width: calc(100% - 32px)">

			<app-text class="d-flex justify-center mt-8" size="large">
				Have you created an account in the last 48 hours?
			</app-text>

			<!--Action Tiles-->
			<div class="d-flex justify-center mt-8 mx-4">

				<!--Regular-->
				<app-btn @click.native="MIX_go('SwappMethod')"
						 class="mr-4 pa-8"
						 label="Yes"
						 style="width: 50%"/>

				<!--Temporary-->
				<app-btn @click.native="isRegistrationFormVisible = true"
						 class="ml-4 pa-8"
						 label="No"
						 style="width: 50%"/>

			</div>

		</div>

		<!--Registration Form -------------------------------------------------------------------------------------- -->

		<div v-if="isRegistrationFormVisible" class="pa-4" style="width: 100%">

			<!--Section Title-->
			<app-text class="mt-4" color="primary">About you</app-text>

			<!--UserName-->
			<app-input input-type="textInput"
					   class="mt-2"
					   :error="errors.userName"
					   :error-messages="errors.userNameErrorMessage"
					   label="Full Name"
					   v-model.trim="form.userName"/>

			<!--Company-->
			<app-input input-type="textInput"
					   class="mt-4"
					   :error="errors.userCompany"
					   :error-messages="errors.userCompanyErrorMessage"
					   label="Company"
					   v-model.trim="form.userCompany"/>

			<!--Person Visiting-->
			<app-input input-type="textInput"
					   class="mt-4"
					   :error="errors.userPersonVisiting"
					   :error-messages="errors.userPersonVisitingErrorMessage"
					   label="Person Visiting"
					   v-model.trim="form.userPersonVisiting"/>

			<!--Section Title-->
			<app-text class="mt-4" color="primary">WIN credentials</app-text>

			<!--Password Criteria-->
			<ul class="ml-4">
				<li>
					<app-text size="small">Min 8 characters</app-text>
				</li>
				<li>
					<app-text size="small">At least 1 uppercase</app-text>
				</li>
				<li>
					<app-text size="small">At least 1 lowercase</app-text>
				</li>
				<li>
					<app-text size="small">At least 1 number</app-text>
				</li>
				<li>
					<app-text size="small">At least 1 special character from <strong>? # @ ! £ $ % &</strong></app-text>
				</li>
			</ul>

			<!--Email-->
			<app-input input-type="textInput"
					   class="mt-2"
					   :error="errors.userEmail"
					   :error-messages="errors.userEmailErrorMessage"
					   label="Email Address"
					   v-model.trim="form.userEmail"/>

			<!--Password-->
			<v-text-field @click:append="isPasswordVisible = !isPasswordVisible"
						  :append-icon="isPasswordVisible ? 'icons8-eye' : 'icons8-closed-eye'"
						  autocomplete="off"
						  background-color="white"
						  class="rounded-lg mt-4"
						  :error="errors.password"
						  :error-messages="errors.passwordErrorMessage"
						  filled
						  hide-details="auto"
						  label="Password"
						  name="yyy"
						  outlined
						  :type="isPasswordVisible ? 'text' : 'password'"
						  value=""
						  v-model.trim="password"/>

			<!--Confirm Password-->
			<v-text-field @click:append="isPasswordVisible = !isPasswordVisible"
						  :append-icon="isPasswordVisible ? 'icons8-eye' : 'icons8-closed-eye'"
						  autocomplete="off"
						  background-color="white"
						  class="rounded-lg mt-4"
						  :error="errors.confirmPassword"
						  :error-messages="errors.confirmPasswordErrorMessage"
						  filled
						  hide-details="auto"
						  label="Confirm Password"
						  name="yyy"
						  outlined
						  :type="isPasswordVisible ? 'text' : 'password'"
						  value=""
						  v-model.trim="confirmPassword"/>

			<!--Consent to use of data-->
			<v-checkbox
				hide-details="auto"
				label="I consent to my data being displayed in app for contact purposes"
				v-model="form.userCanUsePersonalDetails"/>

			<!--Privacy Policy-->
			<v-checkbox
				hide-details="auto"
				:error="errors.privacyPolicyConsent"
				:error-messages="errors.privacyPolicyConsentErrorMessage"
				label="I accept the Terms and Conditions and Privacy Policy"
				v-model="form.privacyPolicyConsent"/>

			<!--Save Button-->
			<app-btn @click.native="registerUser"
					 :block="true"
					 class="mt-4"
					 color="green"
					 label="Save"/>

		</div>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->
		<v-dialog max-width="512"
				  v-model="isErrorDialogVisible">
			<error-dialog @closeErrorDialog="isErrorDialogVisible = false" :error="error"/>
		</v-dialog>


	</div>
</template>

<script>
export default {

	data: () => ({
		confirmPassword: '',
		error: '',
		errors: {
			confirmPassword: false,
			confirmPasswordErrorMessage: '',
			password: false,
			passwordErrorMessage: '',
			privacyPolicyConsent: false,
			privacyPolicyConsentErrorMessage: '',
			userCompany: false,
			userCompanyErrorMessage: '',
			userEmail: false,
			userEmailErrorMessage: '',
			userPersonVisiting: false,
			userPersonVisitingErrorMessage: '',
			userName: false,
			userNameErrorMessage: '',
		},
		form: {
			id: '',
			authId: null,
			lastLocation: {
				locationId: '',
				locationName: '',
			},
			lastSite: {
				siteId: '',
				siteName: '',
			},
			NOKName: '',
			NOKRelationship: '',
			NOKTelephone: '',
			privacyPolicyConsent: false,
			profilePicFileURL: null,
			swappMethod: null,
			swappStatus: 0,
			userAccessAndAbilities: [],
			userAddress: {
				userAddressLine1: '',
				userAddressLine2: '',
				userAddressLine3: '',
				userTown: '',
				userCounty: '',
				userPostcode: '',
			},
			userBio: '',
			userCanUsePersonalDetails: false,
			userCompany: '',
			userDOB: '',
			userEmail: '',
			userIsNtu: false,
			userLevel: 'Visitor-User',
			userName: '',
			userObservationResponsibilities: [],
			userPersonVisiting: '',
			userPosition: '',
			userRole: 'User',
			userStatus: 'APPROVED',
			userTelephone: '',
			userType: 'Visitor',
			userVisitorType: '',
		},
		isErrorDialogVisible: false,
		isPasswordVisible: false,
		isRegistrationFormVisible: false,
		password: '',
		regularOrTemporary: '',
	}),

	methods: {

		/**
		 * Clear Errors and Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Create Document
		 *
		 * Add the missing form fields, and create a new Firebase User document.
		 * If there are no errors, SWAPP the user In.
		 *
		 * @param document the user's form data
		 * @returns {Promise<void>}
		 */
		async createDocument(document) {
			const t = this
			let form = t.form

			form.id = document.uid
			form.userVisitorType = t.regularOrTemporary

			const RESPONSE = await t.MIX_firestore_createDocument('users', form.id, form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating document: ', RESPONSE.error)
				t.handleError('errorCreatingDoc')
				return
			}

			await t.swappUser(form.id)
		},

		/**
		 * Handle Error
		 *
		 * Open the error dialog and display the current error.
		 *
		 * @param error the error code to display
		 */
		handleError(error) {
			const t = this

			t.isErrorDialogVisible = true
			t.error = error
		},

		/**
		 * Register User
		 *
		 * If the form has passed validated, call to register the user.
		 * If there are no errors registering, call to create a Firebase document.
		 *
		 * @returns {Promise<void>}
		 */
		async registerUser() {
			const t = this

			if (!t.validateForm()) return

			const RESPONSE = await t.MIX_auth_register(t.form.userEmail, t.password)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error registering: ', RESPONSE.error)
				t.handleError(RESPONSE.error.code)
				return
			}

			// Create a User document
			await t.createDocument(RESPONSE.data)
		},

		/**
		 * SWAPP User
		 *
		 * SWAPP the current user with their ID and swappDirection.
		 *
		 * @param userId the ID of the user to SWAPP
		 * @returns {Promise<void>}
		 */
		async swappUser(userId) {
			const t = this

			// Convert the word-based direction for the 1/0 database vale
			let swappDirection = t.MIX_getFromLocalStorage('swappDirection')
			swappDirection = swappDirection.toUpperCase() === 'IN' ? 1 : 0

			const RESPONSE = await t.MIX_swapp(userId, swappDirection)

			// Set the params with the SWAPP result (true or false), and go to the SWAPP Result page
			t.MIX_go('SwappResult', {hasErrors: RESPONSE.hasErrors})
		},

		/**
		 * Validate Form
		 *
		 * Check the form for any errors before saving.
		 *
		 * @returns {boolean} boolean whether the form has passed, or not
		 */
		validateForm() {
			const t = this
			/*
            * Password must:
            * - Be at least 8 characters
            * - Contain at least 1 upper case character
            * - Contain at least 1 lower case character
            * - Contain at least 1 number
            * - Contain at least 1 special character from ? # @ ! £ $ % &
            */
			const PASSWORD_REGEX = /(?=.*?[A-Z])(?=.*[a-z])(?=.*?[0-9])(?=.*?[?#@!£$%&]).{8,}/
			const EMAIL_REGEX = /.+@.+\..+/

			t.clearErrorsAndMessages()

			// UserName
			if (!t.form.userName.trim()) {
				t.errors.userName = true
				t.errors.userNameErrorMessage = 'Full Name is required'
			}

			// User Company
			if (!t.form.userCompany.trim()) {
				t.errors.userCompany = true
				t.errors.userCompanyErrorMessage = 'Company is required'
			}

			// User Email
			if (!t.form.userEmail.trim()) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email is required'
			}
			// If the email is not valid
			else if (!EMAIL_REGEX.test(t.form.userEmail)) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Not a valid email format'
			}

			// Person Visiting
			if (!t.form.userPersonVisiting.trim()) {
				t.errors.userPersonVisiting = true
				t.errors.userPersonVisitingErrorMessage = 'Person Visiting is required'
			}

			// Password
			if (!t.password.trim()) {
				t.errors.password = true
				t.errors.passwordErrorMessage = 'Password is required'
			}
			// If the password is less than 8 characters
			else if (t.password.trim().length < 8) {
				t.errors.password = true
				t.errors.passwordErrorMessage = 'Password must be at least 8 characters'
			}
			// If the password is not in the expected format (regex)
			else if (!PASSWORD_REGEX.test(t.password.trim())) {
				t.errors.password = true
				t.errors.passwordErrorMessage = 'Password is not in the expected format'
			}

			// Confirm Password
			if (!t.confirmPassword.trim()) {
				t.errors.confirmPassword = true
				t.errors.confirmPasswordErrorMessage = 'Password is required'
			}
			// If the password is less than 8 characters
			else if (t.confirmPassword.trim().length < 8) {
				t.errors.confirmPassword = true
				t.errors.confirmPasswordErrorMessage = 'Password must be at least 8 characters'
			}
			// If the password is not in the expected format (regex)
			else if (!PASSWORD_REGEX.test(t.confirmPassword.trim())) {
				t.errors.confirmPassword = true
				t.errors.confirmPasswordErrorMessage = 'Password is not in the expected format'
			}

			// Password and Confirm Password must match
			if ((t.password.trim() && t.confirmPassword.trim()) && t.password.trim() !== t.confirmPassword.trim()) {
				t.errors.password = true
				t.errors.passwordErrorMessage = 'Passwords don\'t match'
				t.errors.confirmPassword = true
				t.errors.confirmPasswordErrorMessage = 'Passwords don\'t match'
			}

			// Privacy
			if (!t.form.privacyPolicyConsent) {
				t.errors.privacyPolicyConsent = true
				t.errors.privacyPolicyConsentErrorMessage = 'Agreement is required'
			}

			return (!Object.values(t.errors).includes(true))
		},

	},

	name: "VisitorRegistration",

	watch: {

		/**
		 * Close the form is a different selection has been made.
		 */
		regularOrTemporary() {
			const t = this

			t.isRegistrationFormVisible = false
		},

	},

}
</script>

<style scoped>

</style>
