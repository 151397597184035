import {collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where} from 'firebase/firestore'
import {db} from "@/firebase/firebase-config";

const mixin = {

	methods: {

		/**
		 * MIX Create Document
		 *
		 * Create a Firebase document
		 *
		 * @param collectionName the name of the collection
		 * @param docId the ID of the document
		 * @param document the document data
		 * @returns {Promise<*>} response containing the data:hasErrors:error
		 */
		async MIX_firestore_createDocument(collectionName, docId, document) {
			let response

			// Add created data
			document.createdDateTime = new Date().getTime()
			document.createdUserData = {
				userEmail: document.userEmail,
				userId: docId,
				userName: document.userName
			}

			await setDoc(doc(db, collectionName, docId), document)
				.then(res => {
					response = {
						data: res,
						hasErrors: false,
						error: null,
					}
				})
				.catch(error => {
					console.error('Error creating document: ', error)
					response = {
						data: null,
						hasErrors: true,
						error: error,
					}
				})

			return response
		},

		/**
		 * Get Collection Data
		 *
		 * Get all documents in the given collection, and return then as an array.
		 *
		 * @param collectionName the name of the collection to fetch
		 * @returns {Promise[array]} array of collection documents
		 */
		async MIX_firestore_getCollectionData(collectionName) {
			let collectionData = []

			const QUERY_SNAPSHOT = await getDocs(collection(db, collectionName))

			QUERY_SNAPSHOT.forEach(doc => collectionData.push(doc.data()))

			return collectionData
		},

		/**
		 * Update Document Fields
		 *
		 * Update a document by its ID with the given fields.
		 *
		 * @param collectionName the name of the collection the document belongs to
		 * @param docId the id of the document being updated
		 * @param updatedFields a JSON of the fields/values to update
		 * @returns {Promise<{hasErrors: boolean, data: *, error: null}|{hasErrors: boolean, data: {}, error: *}>}
		 */
		async MIX_firestore_updateDocumentFields(collectionName, docId, updatedFields) {
			const t = this
			const DOC_REF = doc(t.$firebase.db, collectionName, docId)

			return await updateDoc(DOC_REF, updatedFields)
				.then(result => {
					return {
						data: result,
						hasErrors: false,
						error: null,
					}
				})
				.catch(error => {
					return {
						data: {},
						hasErrors: true,
						error: error,
					}
				})

		},

		/**
		 * Get Single Document By ID
		 *
		 * Get a single document using the collection name and document ID.
		 *
		 * @param collectionName the name of the collection to search
		 * @param id the ID of the document of return
		 * @returns {Promise<{hasErrors: boolean, data: {}, error: string}|{hasErrors: boolean, data: DocumentData, error: null}>}
		 */
		async MIX_firestore_getSingleDocumentById(collectionName, id) {
			const DOC_REF = doc(db, collectionName, id)
			const DOC_SNAPSHOT = await getDoc(DOC_REF)

			if (DOC_SNAPSHOT.exists()) {
				return {
					data: DOC_SNAPSHOT.data(),
					hasErrors: false,
					error: null
				}
			} else {
				return {
					data: {},
					hasErrors: true,
					error: 'No document has been found with this ID'
				}
			}

		},

		/**
		 * Get Document Where
		 *
		 * Get a document where the given field matches the given value.
		 *
		 * @param collectionName - the collection name to search
		 * @param field - the field name to search
		 * @param operator - the operator for the where clause
		 * @param value - the value to search for
		 * @returns {Promise<{hasErrors: boolean, data: DocumentData, error: null}|{hasErrors: boolean, data: {}, error: {code: string, message: string}}>}
		 */
		async MIX_firestore_getDocumentWhere(collectionName, field, operator, value) {
			const QUERY = query(collection(db, collectionName), where(field, operator, value))
			return getDocs(QUERY)
				.then(QUERY_SNAPSHOT => {
					// If the query is empty, throw an error
					if (QUERY_SNAPSHOT.empty) {
						throw {
							code: 'auth/user-not-found',
							message: 'No document has been found with this email'
						}
					}

					// Else return the data
					return {
						data: QUERY_SNAPSHOT.docs[0].data(),
						hasErrors: false,
						error: null
					}
				})

				// Handle any errors
				.catch(error => ({
					data: {},
					hasErrors: true,
					error: {
						code: error.code || '',
						message: error.message || ''
					}
				}))
		}

	}

}

export default {
	install(Vue) {
		Vue.mixin(mixin)
	}
}
