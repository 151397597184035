<template>
	<div class="d-flex flex-column align-center">

		<page-title :title="`How would you like to Sign ${swappDirection}?`"/>

		<!--Action Tiles-->
		<div v-if="MIX_getFromLocalStorage('currentSite')"
			 class="centerInPage">

			<div class="d-flex">

				<!--Staff-->
				<action-tile @click.native="MIX_go('Reverse')"
							 icon="qrCode"
							 title="QR Code"/>

				<!--Visitor-->
				<action-tile @click.native="MIX_go('Manual')"
							 icon="computer"
							 title="Manually"/>

			</div>

		</div>

	</div>
</template>

<script>
export default {

	name: "SwappMethod",

	data:() => ({
		swappDirection: '',
	}),

	created() {
		const t = this

		t.swappDirection = t.MIX_getFromLocalStorage('swappDirection')
	}

}
</script>

<style scoped>

</style>
