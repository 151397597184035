<template>
	<div class="d-flex flex-column align-center">

		<page-title :title="`Sign ${swappDirection} Manually`"/>

		<div class="centerInPage text-center pa-4">

			<!--Instruction-->
			<app-text size="normal-bold">Enter your email address to Sign {{ swappDirection }}</app-text>

			<!--Email-->
			<div style="width: 100%; max-width: 343px">

				<!--Email-->
				<v-text-field autocomplete="off"
							  background-color="white"
							  class="rounded-lg mt-4"
							  :error="errors.emailAddress"
							  :error-messages="errors.emailAddressErrorMessage"
							  filled
							  hide-details="auto"
							  label="Email"
							  name="xxx"
							  outlined
							  prepend-inner-icon="icons8-email-sign"
							  :type="fieldTypes.xxx"
							  value=""
							  v-model.trim="emailAddress"/>

				<!--SWAPP Button-->
				<app-btn @click.native="checkUser" :block="true" class="mt-4" :label="`Sign ${swappDirection}`"/>

			</div>

			<!--Dialogs -------------------------------------------------------------------------------------------- -->

			<!--Error Dialog-->
			<v-dialog max-width="512"
					  v-model="isErrorDialogVisible">
				<error-dialog @closeErrorDialog="isErrorDialogVisible = false" :error="error"/>
			</v-dialog>

		</div>
	</div>
</template>

<script>
export default {

	name: "Manual",

	data: () => ({
		emailAddress: '',
		error: '',
		errors: {
			emailAddress: false,
			emailAddressErrorMessage: '',
		},
		fieldTypes: {
			xxx: 'text',
			yyy: 'text',
		},
		isErrorDialogVisible: false,
		swappDirection: '',
	}),

	methods: {

		/**
		 * Check User
		 *
		 * Check if the given user exists in the database.
		 * If they are found, SWAPP them in or out, based on their current swappDirection.
		 *
		 * @returns {Promise<void>}
		 */
		async checkUser() {
			const t = this

			if (!t.validateForm()) return

			const RESPONSE = await t.MIX_firestore_getDocumentWhere('users', 'userEmail', '==', t.emailAddress)
			console.log('RESPONSE: ', RESPONSE)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting User: ', RESPONSE.error)
				t.handleError(RESPONSE.error.code)
				return
			}

			// Swapp the current user in or out, based on their current swappDirection
			await t.swappUser(RESPONSE.data.id)
		},

		/**
		 * Clear Errors and Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Handle Error
		 *
		 * Open the error dialog and display the current error.
		 *
		 * @param error the error code to display
		 */
		handleError(error) {
			const t = this

			t.isErrorDialogVisible = true
			t.error = error
		},

		/**
		 * SWAPP User
		 *
		 * SWAPP the current user with their ID and swappDirection.
		 *
		 * @param userId the ID of the user to SWAPP
		 * @returns {Promise<void>}
		 */
		async swappUser(userId) {
			const t = this
			let swappDirection = t.MIX_getFromLocalStorage('swappDirection')
			swappDirection = swappDirection.toUpperCase() === 'IN' ? 1 : 0

			// Swapp the current user
			const RESPONSE = await t.MIX_swapp(userId, swappDirection)

			// Set the params with the SWAPP result (true or false), and go to the SWAPP Result page
			t.MIX_go('SwappResult', {hasErrors: RESPONSE.hasErrors})
		},

		/**
		 * Validate Form
		 *
		 * Validate all required field in the form.
		 *
		 * @returns {boolean} boolean if the validation has passed, or not
		 */
		validateForm() {
			const t = this
			const EMAIL_REGEX = /.+@.+\..+/

			t.clearErrorsAndMessages()

			// User Email
			if (!t.emailAddress.trim()) {
				t.errors.emailAddress = true
				t.errors.emailAddressErrorMessage = 'Email is required'
			}
			// If the email is not valid
			else if (!EMAIL_REGEX.test(t.emailAddress)) {
				t.errors.emailAddress = true
				t.errors.emailAddressErrorMessage = 'Not a valid email format'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

	created() {
		const t = this

		t.swappDirection = t.MIX_getFromLocalStorage('swappDirection')
	}

}
</script>

<style scoped>

</style>
