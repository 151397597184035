import {version} from '../package.json';

const mixin = {

	data: () => ({
		appVersion: version,

		// Config - Also change:
		//   -> App colors in vuetify.js
		//   -> Manifest colors in public -> manifest.json
		companyLogo: require('@/assets/images/company-logo.svg'),
		appBackground: require('@/assets/images/company-logo-background-small.svg'),
	}),

	methods: {

		/**
		 * MIX Go
		 *
		 * Navigate to given name
		 *
		 * @param name the name of the page to navigate to
		 * @param paramData
		 */
		MIX_go(name, paramData) {
			const t = this

			t.$router.push({name: name, params: {data: paramData}})
				.catch(error => console.error('Routing error: ', error))

		},

		/**
		 * SWAPP
		 *
		 * SWAPP In the current user using their ID and the current site and location data.
		 * The user ID is obtained from either:
		 *     -> Reverse SWAPP - QR Code
		 *     -> Manual SWAPP - Logging In
		 *
		 * @param userId the ID of the document to update
		 * @param swappDirection whether the user is SWAPPing in (1) or out (0)
		 * @returns {Promise<void>}
		 */
		async MIX_swapp(userId, swappDirection) {
			const t = this

			const LOCATION_DATA = t.MIX_getFromLocalStorage('currentLocation')
			const SITE_DATA = t.MIX_getFromLocalStorage('currentSite')
			const UPDATED_FIELDS = {}

			UPDATED_FIELDS.lastSite = {}
			UPDATED_FIELDS.lastLocation = {}

			UPDATED_FIELDS.lastSite.siteId = SITE_DATA.id
			UPDATED_FIELDS.lastSite.siteName = SITE_DATA.siteName
			UPDATED_FIELDS.lastLocation.locationId = LOCATION_DATA.id
			UPDATED_FIELDS.lastLocation.locationName = LOCATION_DATA.locationName

			UPDATED_FIELDS.swappMethod = 'Reverse'
			UPDATED_FIELDS.swappStatus = swappDirection
			UPDATED_FIELDS.swappTime = new Date().getTime()

			UPDATED_FIELDS.modifiedDateTime = new Date().getTime()
			UPDATED_FIELDS.modifiedUserData = {userId}

			return await t.MIX_firestore_updateDocumentFields('users', userId, UPDATED_FIELDS)
		},

		/**
		 * Add To Local Storage
		 *
		 * Save the data to local storage using the given tag.
		 * The site/location data is saved to local storage so the device is always ready with minimum config requirements.
		 *
		 * @param tag the tag to save the data with
		 * @param data the data to save
		 */
		MIX_addToLocalStorage(tag, data) {
			localStorage.setItem(tag, JSON.stringify(data))
		},

		/**
		 * Get From Local Storage
		 *
		 * Get the saved data from local storage using the given tag.
		 *
		 * @param tag the tag used to fetch the data
		 * @returns {any} the data as a JSON
		 */
		MIX_getFromLocalStorage(tag) {
			return JSON.parse(localStorage.getItem(tag))
		},

		/**
		 * Clear Local Storage
		 *
		 * Clear all data from local storage.
		 */
		MIX_clearLocalStorage() {
			localStorage.clear()
		}

	}

}

export default {
	install(Vue) {
		Vue.mixin(mixin)
	}
}
