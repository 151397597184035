<template>
	<div class="qrReader-container">

		<div @click="isHelpDialogVisible = true"
			 style="background: white; border-radius: 50%; position:fixed; top: 80px; right: 16px; z-index: 999;">
			<app-icon
				color="primary"
				icon="help"
				size="48"
				style="outline: 4px solid var(--v-primary-base); border-radius: 50%; outline-offset: -6px"/>
		</div>

		<!--Camera Buttons-->
		<!--<div class="qrReader-cameraButtons-container">-->
		<!--	<app-btn @click.native="switchCamera('default')" label="Default"/>-->
		<!--	<app-btn @click.native="switchCamera('front')" label="Front" class="mx-4"/>-->
		<!--	<app-btn @click.native="switchCamera('rear')" label="Rear" class="mr-4"/>-->
		<!--</div>-->

		<!--Error Messages-->
		<div v-if="error" class="centerInPage text-center pa-4">
			<app-text class="" v-if="noFrontCamera">You don't seem to have a front camera on your device</app-text>
			<app-text class="" v-if="noRearCamera">You don't seem to have a rear camera on your device</app-text>
			<app-text class="mt-4" v-if="error">{{ error }}</app-text>
		</div>

		<!--QR Reader-->
		<qrcode-stream v-if="!error"
					   @decode="onDecode"
					   @init="onInit"
					   :camera="camera || null"
					   class="qrReader-reader animate__animated animate__zoomIn animate__faster"
					   :track="paintOutline">

			<!--Loader-->
			<div class="d-flex align-center justify-center" style="height: 100%; width: 100%">
				<app-text v-if="isPageLoading" size="xlarge">Loading...</app-text>
			</div>

			<!--Instructions-->
			<div class="d-flex align-center justify-center"
				 style="height: 100%; width: 100%; position:absolute; top:0; bottom:0">

				<!--QR Icon-->
				<app-icon color="#FFFFFF33" icon="qrCode" size="400" style="position:absolute;"
						  class="animate__animated animate__pulse animate__fast animate__infinite"/>

				<!--Instruction-->
				<app-text color="white"
						  size="large">
					Hold up your QR code
				</app-text>

			</div>

		</qrcode-stream>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->
		<v-dialog max-width="512"
				  v-model="isErrorDialogVisible">
			<error-dialog @closeErrorDialog="isErrorDialogVisible = false" :error="error"/>
		</v-dialog>

		<v-dialog max-width="512"
				  v-model="isHelpDialogVisible">
			<reverse-help-dialog @closeHelpDialog="isHelpDialogVisible = false"/>
		</v-dialog>

	</div>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'
import ReverseHelpDialog from "@/views/reverse/reverseHelpDialog/ReverseHelpDialog";

export default {

	name: "QrCodeReader",

	components: {ReverseHelpDialog, QrcodeStream},

	data: () => ({
		camera: 'front',
		error: '',
		isErrorDialogVisible: false,
		isHelpDialogVisible: false,
		isPageLoading: false,
		noFrontCamera: false,
		noRearCamera: false,
		swappDirection: '',
	}),

	methods: {

		/**
		 * Get Current User's Data
		 *
		 * Get the logged-in user's data to get their swapp status.
		 *
		 * @param userId the ID of the document to fetch
		 * @returns {Promise<void>}
		 */
		async getCurrentUserData(userId) {
			const t = this

			// Get the logged-in user's collection data
			const RESPONSE = await t.MIX_firestore_getSingleDocumentById('users', userId)

			// If there are any error fetching the user's document, display an error
			if (RESPONSE.hasErrors) {
				console.error('Error reading user document: ', RESPONSE.error)
				t.handleError('errorReadingDoc')
				return
			}

			// Swapp the current user
			await t.swappUser(userId)
		},

		/**
		 * Handle Error
		 *
		 * Open the error dialog and display the current error.
		 *
		 * @param error the error code to display
		 */
		handleError(error) {
			const t = this

			t.isErrorDialogVisible = true
			t.error = error
		},

		/**
		 * On Decode
		 *
		 * When the read has decoded the QR code (getting the user's ID),
		 * get the current user's document and check their swappStatus to know whether to swapp them in or out.
		 *
		 * @param userId the decode user's ID
		 * @returns {Promise<void>}
		 */
		async onDecode(userId) {
			const t = this

			await t.getCurrentUserData(userId)
		},

		/**
		 * On Init
		 *
		 * Initialise the QR code reader by checking for loading and camera errors.
		 * Any errors will stop the camera from loading.
		 *
		 * @param promise
		 * @returns {Promise<void>}
		 */
		async onInit(promise) {
			const t = this
			t.isPageLoading = true

			// Initialisation Errors
			try {
				await promise
			} catch (error) {
				if (error.name === 'NotAllowedError') t.error = "ERROR: you need to grant camera access permission"
				else if (error.name === 'NotFoundError') t.error = "ERROR: no camera on this device"
				else if (error.name === 'NotSupportedError') t.error = "ERROR: secure context required (HTTPS, localhost)"
				else if (error.name === 'NotReadableError') t.error = "ERROR: is the camera already in use?"
				else if (error.name === 'OverconstrainedError') t.error = "ERROR: installed cameras are not suitable"
				else if (error.name === 'StreamApiNotSupportedError') t.error = "ERROR: Stream API is not supported in this browser"
				else if (error.name === 'InsecureContextError') t.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
				else t.error = `ERROR: Camera error (${error.name})`
			}

			// Camera Errors
			try {
				await promise
			} catch (error) {
				const triedFrontCamera = t.camera === 'front'
				const triedRearCamera = t.camera === 'rear'

				const cameraMissingError = error.name === 'OverconstrainedError'

				if (triedRearCamera && cameraMissingError) t.noRearCamera = true

				if (triedFrontCamera && cameraMissingError) t.noFrontCamera = true

				console.error(error)
			}

			t.isPageLoading = false
		},

		/**
		 * Paint Outline
		 *
		 * Track the QR code with a colored outline when it has been found
		 *
		 * @param detectedCodes
		 * @param ctx
		 */
		paintOutline(detectedCodes, ctx) {
			for (const detectedCode of detectedCodes) {
				const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

				ctx.strokeStyle = "lightgreen"

				ctx.beginPath();
				ctx.moveTo(firstPoint.x, firstPoint.y)
				for (const {x, y} of otherPoints) {
					ctx.lineTo(x, y);
				}
				ctx.lineTo(firstPoint.x, firstPoint.y)
				ctx.closePath()
				ctx.stroke()
			}
		},

		/**
		 * SWAPP User
		 *
		 * SWAPP the current user with their ID and swappDirection.
		 *
		 * @param userId the ID of the user to SWAPP
		 * @returns {Promise<void>}
		 */
		async swappUser(userId) {
			const t = this

			// Convert the word-based direction for the 1/0 database vale
			let swappDirection = t.MIX_getFromLocalStorage('swappDirection')
			swappDirection = swappDirection.toUpperCase() === 'IN' ? 1 : 0

			// Swapp the current user
			const RESPONSE = await t.MIX_swapp(userId, swappDirection)

			// Set the params with the SWAPP result (true or false), and go to the SWAPP Result page
			t.MIX_go('SwappResult', {hasErrors: RESPONSE.hasErrors})
		},

		/**
		 * Switch Camera
		 *
		 * Switch the device's cameras between Front, Rear, and Default.
		 *
		 * @param camera the selection from the switcher buttons for which camera to use
		 */
		switchCamera(camera) {
			const t = this

			// Reset Errors
			t.noRearCamera = false
			t.noFrontCamera = false
			t.error = ''

			switch (camera) {
				case 'front':
					t.camera = 'front'
					break
				case 'rear':
					t.camera = 'rear'
					break
				default:
					t.camera = ''
			}

		}

	},

	created() {
		const t = this

		t.swappDirection = t.MIX_getFromLocalStorage('swappDirection')
	}

}
</script>

<style scoped>
.qrReader-container {
	/*height: 100%;*/
	/*width: 100%*/
}

.qrReader-cameraButtons-container {
	display: flex;
	justify-content: flex-end;

	position: absolute;
	top: 16px;
	right: 0;
	z-index: 9;
}

.qrReader-reader {
	height: calc(100vh - 64px);
	width: 100%;
}
</style>
