<template>
	<div class="d-flex flex-column align-center px-4">

		<page-title title="Reset Password"/>

		<!--Instruction-->
		<app-text class="text-center mt-16" size="medium">
			To reset your password, enter your email address into the box below and press the Reset button
			<br>
			<br>
			An email will be sent to you with a link to change your password
		</app-text>

		<div class="centerInPage text-center pa-4">

			<!--Email | Password-->
			<div style="width: 100%; max-width: 343px">

				<!--Email-->
				<v-text-field autocomplete="off"
							  background-color="white"
							  class="rounded-lg"
							  :error="errors.emailAddress"
							  :error-messages="errors.emailAddressErrorMessage"
							  filled
							  hide-details="auto"
							  label="Email"
							  name="xxx"
							  outlined
							  prepend-inner-icon="icons8-email-sign"
							  value=""
							  v-model.trim="emailAddress"/>

				<!--Reset Button-->
				<app-btn @click.native="handleResetPassword" :block="true" class="mt-4" label="Reset"/>

			</div>

			<!--Dialogs ------------------------------------------------------------------------------------------------ -->
			<v-dialog max-width="512"
					  v-model="isErrorDialogVisible">
				<error-dialog @closeErrorDialog="isErrorDialogVisible = false" :error="error"/>
			</v-dialog>

			<v-dialog max-width="512"
					  v-model="isConfirmationDialogVisible">
				<div class="white rounded-lg">

					<!--Toolbar-->
					<v-toolbar class="rounded-t-lg pl-2" color="primary">
						<app-text color="white" size="medium">Email Sent</app-text>
						<v-spacer/>
						<close-icon @click.native="closeConfirmationDialog"/>
					</v-toolbar>

					<!--Content-->
					<div class="d-flex flex-column align-center pa-4">

						<app-text class="text-center" size="medium">
							An email has been sent to
							<br>
							<br>
							<strong>{{ emailAddress }}</strong>
							<br>
							<br>
							Follow the link in the email to reset your password
							<br>
							<br>
							If you have not received an email in a few minutes, please check you email's junk folder
						</app-text>

						<app-btn @click.native="closeConfirmationDialog"
								 :block="true"
								 class="mt-8"
								 label="Close"/>

						<app-text class="mt-4" color="grey">Redirecting in {{ secondsRemaining }}</app-text>

					</div>

				</div>

			</v-dialog>

		</div>
	</div>
</template>

<script>
export default {

	name: "ResetPassword",

	data: () => ({
		emailAddress: '',
		error: '',
		errors: {
			emailAddress: false,
			emailAddressErrorMessage: '',
		},
		isConfirmationDialogVisible: false,
		isErrorDialogVisible: false,
		secondsRemaining: 5,
	}),

	methods: {

		/**
		 * Close Confirmation Dialog
		 *
		 * Close the dialog and reset the email field.
		 */
		closeConfirmationDialog() {
			const t = this

			t.emailAddress = ''
			t.isConfirmationDialogVisible = false
			t.redirectToHomePage()
		},

		/**
		 * Handle Error
		 *
		 * Open the error dialog and display the current error.
		 *
		 * @param error the error code to display
		 */
		handleError(error) {
			const t = this

			t.isErrorDialogVisible = true
			t.error = error
		},

		/**
		 * Handle Reset Password
		 *
		 * Call to send a password reset email to the user's email.
		 *
		 * @returns {Promise<void>}
		 */
		async handleResetPassword() {
			const t = this

			// Only proceed if the form has passed validation
			if (!t.validateForm()) return

			const RESPONSE = await t.MIX_auth_resetPassword(t.emailAddress)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error resetting password: ', RESPONSE.error)
				t.handleError(RESPONSE.error.code)
				return
			}

			t.isConfirmationDialogVisible = true

			t.startCountdownTimer()
		},

		/**
		 * Redirect To Home Page
		 *
		 * Redirect the user to the Home page and log them out.
		 */
		redirectToHomePage() {
			const t = this

			t.MIX_auth_logOut()
		},

		/**
		 * Start Count Down Timer
		 *
		 * Sets a timer, redirecting to the Home page when complete (if the Close button hasn't been pressed).
		 */
		startCountdownTimer() {
			const t = this
			let seconds = t.secondsRemaining

			let counter = setInterval(async () => {

				if (seconds === 0) {
					clearInterval(counter)
					t.redirectToHomePage()
				} else {
					seconds--
					t.secondsRemaining = seconds
				}

			}, 1000)
		},

		/**
		 * Validate Form
		 *
		 * Validate all required field in the form.
		 *
		 * @returns {boolean} boolean if the validation has passed, or not
		 */
		validateForm() {
			const t = this

			// Reset errors
			t.errors.emailAddress = false
			t.errors.emailAddressErrorMessage = ''

			// Email
			if (!t.emailAddress.trim()) {
				t.errors.emailAddress = true
				t.errors.emailAddressErrorMessage = 'Email is required'
			}

			return !Object.values(t.errors).includes(true)
		}

	}

}
</script>

<style scoped>

</style>
