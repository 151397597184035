<template>
	<div class="white rounded-lg">

		<!--Toolbar-->
		<v-toolbar class="rounded-t-lg pl-2" color="primary">
			<app-text color="white" size="medium">Error</app-text>
			<v-spacer/>
			<close-icon @click.native="$emit('closeErrorDialog')"/>
		</v-toolbar>

		<div class="d-flex flex-column align-center pa-4">

			<app-icon color="red" icon="error" size="48"/>

			<app-text class="mt-4">{{ handleError }}</app-text>

		</div>

	</div>
</template>

<script>
export default {

	name: "ErrorDialog",

	props: ['error'],

	computed: {

		handleError() {
			const t = this
			const error = t.$props.error
			let errorMessage = t.$props.error

			if (error === 'auth/email-already-in-use') errorMessage = 'This email address is already in use by another account'
			else if (error === 'auth/internal-error') errorMessage = 'Oh no! Something  went wrong, please try again'
			else if (error === 'auth/invalid-email') errorMessage = 'The email is not valid'
			else if (error === 'auth/too-many-requests') errorMessage = 'Temporarily blocked due too many attempts, please try again later'
			else if (error === 'auth/user-not-found') errorMessage = 'No account has been found for this email address'
			else if (error === 'auth/wrong-password') errorMessage = 'Email or Password is wrong'
			else if (error === 'errorCreatingDoc') errorMessage = 'There was a problem creating your User account, please contact a manager.'
			else if (error === 'errorReadingDoc') errorMessage = 'There was a problem reading your User account, please contact a manager.'
			else errorMessage = 'Oh no! Something unexpected has gone wrong, please try again'

			return errorMessage
		}

	}

}
</script>

<style scoped>

</style>
