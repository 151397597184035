<template>
	<div>

		<!--Text Input-->
		<div v-if="inputType === 'textInput'">

			<!--Field-->
			<v-text-field @input="updateValue($event)"
						  @click:append="handleAppendClick"
						  autocomplete="off"
						  :append-icon="appendIcon"
						  background-color="white"
						  class="rounded-lg"
						  color="primary"
						  :disabled="disabled"
						  :error="errors"
						  :error-messages="errorMessages"
						  flat
						  filled
						  hide-details="auto"
						  :label="label"
						  outlined
						  :placeholder="placeholder"
						  :prepend-inner-icon="prependInnerIcon"
						  :type="type || 'text'"
						  :value="value"/>

		</div>

		<!--Text Area-->
		<div v-if="inputType === 'textArea'">

			<!--Field-->
			<v-textarea @input="updateValue($event)"
						class="rounded-lg"
						color="primary"
						:disabled="disabled"
						:error="errors"
						:error-messages="errorMessages"
						flat
						hide-details="auto"
						:label="label"
						outlined
						:placeholder="placeholder"
						:rows="rows || 4"
						solo
						:type="type || 'text'"
						:value="value"/>

		</div>

		<!--Select-->
		<div v-if="inputType === 'select'">

			<!--Field-->
			<v-select @input="updateValue($event)"
					  class="rounded-lg"
					  color="primary"
					  :disabled="disabled"
					  :error="errors"
					  :error-messages="errorMessages"
					  flat
					  hide-details="auto"
					  item-color="primary"
					  :items="items"
					  :item-text="itemText"
					  :item-value="itemValue"
					  :label="label"
					  :multiple="multiple"
					  outlined
					  :placeholder="placeholder"
					  :return-object="returnObject"
					  solo
					  :value="value"/>

		</div>

		<!--Autocomplete-->
		<div v-if="inputType === 'autoComplete'">

			<!--Field-->
			<v-autocomplete @input="updateValue($event)"
							chips
							class="rounded-lg"
							color="primary"
							deletable-chips
							:disabled="disabled"
							:error="errors"
							:error-messages="errorMessages"
							flat
							hide-details="auto"
							item-color="primary"
							:items="items"
							:item-text="itemText"
							:item-value="itemValue"
							:label="label"
							multiple
							outlined
							:placeholder="placeholder"
							small-chips
							solo
							:value="value"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "AppInput",

	props: [
		'appendIcon',
		'chips',
		'deletableChips',
		'disabled',
		'errors',
		'errorMessages',
		'inputType',
		'items',
		'itemText',
		'itemValue',
		'label',
		'multiple',
		'placeholder',
		'prependInnerIcon',
		'returnObject',
		'rows',
		'type',
		'value',
	],

	methods: {

		/**
		 * Update Value
		 *
		 * Emit the value back to the parent's v-model.
		 *
		 * @param value the entered value to emit back
		 */
		updateValue(value) {
			this.$emit('input', value)
		},

		handleAppendClick() {
			this.$emit('appendClick')
		},

	}

}
</script>

<style scoped>

</style>
