import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from '@/router.js'
import vuetify from '@/vuetify'
import VueQrcodeReader from 'vue-qrcode-reader'

/**
 * Animations
 */
import '../node_modules/animate.css/animate.min.css'

/**
 * Firebase
 */
// Firebase
const firebase = require("@/firebase/firebase-config.js");
Vue.prototype.$firebase = firebase;
// Firebase Firestore Mixin
import firebaseFirestoreMixin from '@/firebase/firebase-firestore-mixin.js'
Vue.use(firebaseFirestoreMixin)
// Firebase Auth Mixin
import firebaseAuthMixin from '@/firebase/firebase-auth-mixin.js'
Vue.use(firebaseAuthMixin)

/**
 * Icons
 */
import "@/assets/icons/css/styles.min.css";

/**
 * Mixins
 */
import mixin from '@/mixin.js';
Vue.use(mixin);

/**
 * Global Components
 */
import ActionTile from "@/components/ActionTile";
Vue.component('ActionTile', ActionTile)

import AppBtn from "@/components/app/AppBtn";
Vue.component('AppBtn', AppBtn)

import AppIcon from "@/components/app/AppIcon";
Vue.component('AppIcon', AppIcon)

import AppInput from "@/components/app/AppInput";
Vue.component('AppInput', AppInput)

import AppText from "@/components/app/AppText";
Vue.component('AppText', AppText)

import CloseIcon from "@/components/CloseIcon";
Vue.component('CloseIcon', CloseIcon)

import ErrorDialog from "@/components/ErrorDialog";
Vue.component('ErrorDialog', ErrorDialog)

import PageTitle from "@/components/PageTitle";
Vue.component('PageTitle', PageTitle)

import QrCodeReader from "@/views/reverse/QrCodeReader";
Vue.component('QrCodeReader', QrCodeReader)

Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
