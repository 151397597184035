import {createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail} from 'firebase/auth'

const mixin = {

	methods: {

		/**
		 * Log Out
		 */
		MIX_auth_logOut() {
			const t = this
			getAuth()

				.signOut()

				.then(() => {
					t.MIX_go('Home')
				})

				.catch(error => {
					console.error('Error logging out - code: ', error.code)
					console.error('Error logging out - message: ', error.message)
				})
		},

		/**
		 * Mix Register
		 *
		 * Register for a new Firebase auth account with an email and password.
		 *
		 * @param email the user's email
		 * @param password the user's password
		 * @returns {Promise<*>} response containing the data:hasErrors:error
		 */
		async MIX_auth_register(email, password) {
			const t = this
			const AUTH = getAuth()
			let response

			await createUserWithEmailAndPassword(AUTH, email, password)
				.then(userCredentials => {
					response = {
						data: userCredentials.user,
						hasErrors: false,
						error: null,
					}
				})
				.catch(error => {
					console.error('Error registering: ', error)
					console.error('Error registering - code: ', error.code)
					console.error('Error registering - message: ', error.message)

					response = {
						data: null,
						hasErrors: true,
						error: error,
					}
				})

			return response
		},

		/**
		 * Reset Password
		 *
		 * Send a password reset email to the user's email address.
		 *
		 * @param email the user's email to send to
		 * @returns {Promise<*>} response object data:hasErrors:error
		 */
		async MIX_auth_resetPassword(email) {
			const AUTH = getAuth()
			let response

			await sendPasswordResetEmail(AUTH, email)
				.then(() => {
					response = {
						data: null,
						hasErrors: false,
						error: null,
					}
				})
				.catch(error => {
					console.error('Error resetting password: ', error)
					response = {
						data: null,
						hasErrors: true,
						error: error,
					}
				})

			return response
		},

	}

}

export default {
	install(Vue) {
		Vue.mixin(mixin)
	}
}
