import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const opts = {
	theme: {
		options: {customProperties: true}, themes: {
			light: {

				// App colors
				appBackground: '#F5F5F5',
				primary: '#0A233E',
				accent: '#fcb315',

				green: '#4CAF50',
				red: '#FF3D00',

				// Greys
				grey3: '#333333',
				grey6: '#666666',
				grey9: '#999999',
				greyD: '#DDDDDD',

				error: '#FF3D00', // same as red
				vindicoPurple: '#582468'

			}
		}
	}
}

export default new Vuetify(opts)
